import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';
import React from 'react';
import { Autoplay, EffectFade, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Flex, NavLink, Paragraph } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';

function PromoMessage({ text, isExternal, newTab, url }) {
  const renderMessageType = () => {
    if (url && !isExternal) {
      return (
        <Link href={url} prefetch={false}>
          <Paragraph
            sx={{ textAlign: 'center', lineHeight: '36px' }}
            variant="text.body.s"
          >
            {text}
          </Paragraph>
        </Link>
      );
    }
    if (url && isExternal) {
      return (
        <NavLink
          href={url}
          target={newTab ? '_blank' : '_self'}
          rel={newTab ? 'noopener noreferrer' : ''}
          sx={{
            textDecoration: 'none',
          }}
        >
          <Paragraph
            sx={{ textAlign: 'center', lineHeight: '36px' }}
            variant="text.body.s"
          >
            {text}
          </Paragraph>
        </NavLink>
      );
    }

    return (
      <Paragraph
        sx={{ textAlign: 'center', lineHeight: '36px' }}
        variant="text.body.s"
      >
        {text}
      </Paragraph>
    );
  };

  return (
    <Flex
      data-comp="PromoBarMessage"
      variant="flex.row.center"
      sx={{
        height: '36px',
        color: 'white',
        width: '100%',
      }}
    >
      {renderMessageType()}
    </Flex>
  );
}
export function PromoBar() {
  const siteSettings = useSettings();
  const { header } = siteSettings;
  const { promoBar } = header;
  const [userLoc, setLocation] = React.useState(null);
  const [promobarMessages, setMessages] = React.useState([]);
  const haveMessages = promobarMessages.length;
  const singleMessage = haveMessages === 1;

  React.useEffect(() => {
    if (document) {
      document.documentElement.style.setProperty('--promoBar-height', '36px');
    }
  }, []);

  React.useEffect(() => {
    fetch('/api/loc-test')
      .then((data) => {
        return data.json();
      })
      .then((location) => {
        if (location['x-country']) {
          setLocation(location['x-country']);
        }
      });
  }, [siteSettings]);

  React.useEffect(() => {
    let messages = [];

    if (!promoBar || !promoBar.messages) return [];

    if (!userLoc) {
      messages = promoBar.messages.filter((message) => {
        return (
          !message?.locations?.length || message?.locations?.includes('US')
        );
      });
    } else {
      messages = promoBar.messages.filter((message) => {
        return (
          !message?.locations?.length || message?.locations?.includes(userLoc)
        );
      });
    }

    setMessages(messages);
  }, [promoBar, userLoc]);

  if (!haveMessages) {
    return null;
  }

  return promoBar?.enabled ? (
    <Flex
      data-comp="PromoBar"
      sx={{
        px: 5,
        position: 'relative',
        backgroundColor: promoBar?.backgroundColor,
        minHeight: 36,
        maxHeight: 36,
        alignItems: 'center',
        width: '100%',
        '.swiper-slide': {
          pointerEvents: 'all',
        },
      }}
    >
      {singleMessage ? (
        <PromoMessage {...promobarMessages?.[0]?.message} />
      ) : (
        <Swiper
          data-comp="PromoBarMessages"
          init
          modules={[EffectFade, Autoplay, Virtual]}
          autoplay={{
            enabled: true,
            delay: promoBar.duration * 1000,
          }}
          fadeEffect={{ crossFade: true }}
          effect="fade"
          virtual
          sx={{
            width: '100%',
          }}
          css={{
            '.swiper-wrapper': { height: '36px' },
            '.swiper-slide': { height: '36px' },
          }}
        >
          {promobarMessages?.map((message) => {
            return (
              <SwiperSlide key={uuidv4()}>
                <PromoMessage {...message?.message} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Flex>
  ) : null;
}

PromoBar.displayName = 'PromoBar';
